import { Card, Grid, Heading, Paragraph, PrimaryButton, TertiaryButton, Text, TextLink } from '@mlc/web-ui-toolkit';
import React, { useContext, useState } from 'react';
import { AppThemeContext } from '../../../../context/AppThemeContext';
import { FrequencyValues } from "../../../../logic/domains/calculator.domain";
import { Member } from '../../../../logic/domains/memberData.domain';
import { TargetSetItem } from '../../../../logic/domains/targetSet.domain';
import { asDecimalCurrency } from '../../../../logic/functions/formatter';
import { logAction } from '../../../../logic/functions/logging.function';
import { closedOptionExists, getOptionListString, getProfileTypeName, opensInNewWindow, shouldHoldInvestments, showNcc } from '../../../../logic/functions/util';
import { StyledModal, StyledOptionsList, WiderModal } from '../../Common/Common.style';
import { MobileDeviceQ } from '../../Common/MediaQ';
import { Image } from '../../Image';
import takeActionTestIds from '../TakeAction.elements';
import { ActionsLabel, LearnMoreLink, ProfileOptionName, TakeActionAccordion, TakeActionContainer, TakeActionContent, TakeActionHelpImageCard, TakeActionHelpMainCard, TakeActionImage, TakeActionListCard, TakeActionSection, TakeActionSectionButtons, TakeActionSectionHeading } from '../TakeAction.style';
import TakeActionDetailsTable from './TakeActionDetailsTable';
import { RiskProfileMixType } from '../../../../enums';

type Props = {
    memberData: Member;
    targetSets: TargetSetItem[];
    frequencyValues: FrequencyValues;
}

const TakeActionDetails = ({ memberData, targetSets, frequencyValues }: Props) => {
    const content: any = useContext(AppThemeContext).staticContent.takeAction(memberData.productName);
    const { isMlc } = useContext(AppThemeContext);

    const { keepExistingInvestmentMix, selectedMix, currentMix, riskProfileMixType, contributionAmount, proposedContribution, bpayRef, activeInvestment } = memberData;

    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [bpayModalContent, setBpayModalContent] = useState<any>("");
    const [investmentsOpen, setInvestmentsOpen] = useState(false);
    const [isCoachModalOpen, setIsCoachModalOpen] = useState(false);
    const [accordions, setAccordions] = useState([false, false, false]);
    const [coachBtnToFocus, setCoachBtnToFocus] = useState("");

    const openAccordion = (num: number) => {
        let newAccordions = accordions.map((value: boolean, index: number) => (index === num ? !value : value));
        setAccordions(newAccordions);
    }

    //to avoid repeating re-used text multiple times
    const commonHelpText = {
        hereToHelp: "We're here to help.",
        gettingHelp: `Getting help at no additional cost is all part of being with ${isMlc ? 'MLC' : 'Plum'}.`,
        getAdvice: "You can get general super advice, and help taking important steps with your super.",
        callUs: `Call us on ${content.contactNo} (9:00am - 5:30pm, Monday to Friday AEST/AEDT).`,
        financialAdvice: "Financial advice that's all about you",
        asAMember: <><b>{memberData.firstName}</b>, as a {isMlc ? 'MLC' : 'Plum'} member you have a range of advice options at your fingertips, at no additional cost.</>,
        yourGoals: "Your financial goals and aspirations are unique, and the advice you receive should be too.",
        hereToHelp2: "We're here to help you achieve your best possible financial outcome."
    };

    const TalkToCoachLink = ({ id }: any) => {
        return (
            <TertiaryButton id={id} onClick={() => {
                setIsCoachModalOpen(true);
                setCoachBtnToFocus(id);
                logAction("NEXT_ACTIONS_TALK_TO_COACH")
            }} type="button">Talk to a Financial Coach</TertiaryButton>
        );
    };

    const FinancialAdviceLink = () => (
        <TertiaryButton
            onClick={() => {
                logAction("NEXT_ACTIONS_LEARN_FINANCIAL_ADVICE", true);
                window.open(content.financialAdviceUrl, '_blank', 'noopener');
            }}
            type="button"
            data-id={takeActionTestIds.learnFinancialAdvice}
        >Learn more</TertiaryButton>
    );

    const BookingBtn = () => (
        <PrimaryButton
            onClick={() => {
                logAction("BOOKING_LINK", true);
                window.open(content.bookingUrl, '_blank', 'noopener');
            }}
            type="button"
            data-id={takeActionTestIds.bookingBtn}
        >Book an appointment</PrimaryButton>
    )

    const getInvestmentsContent = (isMobile: boolean) => {
        //avoid errors around null target sets
        if (!hasRiskProfile) { return null }

        const headerImg = (
            <TakeActionImage>
                <img src={content.investmentOptionImg} alt="" />
            </TakeActionImage>
        );

        //maintain investments
        if (keepExistingInvestmentMix) {
            return (<>
                {headerImg}
                <Card>
                    <Text data-id={takeActionTestIds.maintainInvestmentMix}>You have decided to maintain your current investment mix.</Text>
                </Card>
                <TakeActionSectionButtons marginTop="15px">
                    <TalkToCoachLink id="coach-link-1" />
                </TakeActionSectionButtons>
            </>);
        } else {
            return (
                <>
                    {headerImg}
                    <Card>
                        <Text>Existing risk profile</Text>{' '}
                        <ProfileOptionName data-id={takeActionTestIds.currentRiskProfile}>{getProfileTypeName(currentMix.type!)}</ProfileOptionName>
                    </Card>
                    <Card>
                        <Text>{selectedMix.type === riskProfileMixType ? 'Recommended' : 'Selected'} risk profile</Text>{' '}
                        <ProfileOptionName data-id={takeActionTestIds.suggestedRiskProfile}>{getProfileTypeName(selectedMix.type!)}</ProfileOptionName>
                    </Card>
                    <Card>
                        <Text>Recommended investment option</Text>{' '}
                        <ProfileOptionName className="option" data-id={takeActionTestIds.recommendedOption}>{targetSets[0].options[0].name}</ProfileOptionName>
                    </Card>
                    {/* hold investments */}
                    {shouldHoldInvestments(currentMix, targetSets) ? (
                        <>
                            <Card marginTop="20px">
                                <Paragraph data-id={takeActionTestIds.holdInvestments}>
                                    We determined you have a risk profile that is {getProfileTypeName(selectedMix.type!)}. Continue to hold your investments in the {getOptionListString(targetSets)} as your risk profile matches your current investment strategy.
                                </Paragraph>
                            </Card>
                            <TakeActionSectionButtons>
                                <TalkToCoachLink id="coach-link-2" />
                            </TakeActionSectionButtons>
                        </>
                    ) : (
                        <>
                            <TakeActionListCard marginTop="20px">
                                <ul>
                                    {!activeInvestment && (selectedMix.type === RiskProfileMixType.Conservative || selectedMix.type === RiskProfileMixType.High_Growth) && (
                                        <>
                                            <li>We have made this recommendation as there was no alternative lower cost option that suited your risk profile.</li>
                                        </>
                                    )}
                                    <li>
                                        Check the changes in your{' '}
                                        <TextLink
                                            el="button"
                                            type="button"
                                            onClick={() => {
                                                logAction("INV_FEES_LINK");
                                                setInvestmentsOpen(true);
                                            }}
                                            data-id={takeActionTestIds.investmentTableLink}
                                        >
                                            <Text color="black"><strong>investment fees</strong></Text>
                                        </TextLink>
                                        {' '}for the recommended investment option.
                                    </li>
                                    <li>We recommend you change your existing investments and future contributions into the <strong>{targetSets[0].options[0].name}</strong> option.</li>
                                </ul>
                            </TakeActionListCard>
                            {/* closed option */}
                            {closedOptionExists(currentMix.investmentOptions) && targetSets.length === 1 && (
                                <Card marginBottom="20px">
                                    <Paragraph data-id={takeActionTestIds.closedInvestmentOptionContent}>{content.closedInvestmentOption}</Paragraph>
                                </Card>
                            )}
                            {!isMobile && (
                                <Paragraph>
                                    Our switch transaction lets you choose where you'd like your existing money to be invested. Please make sure your request includes both your current investments and future investment contribution strategies.
                                </Paragraph>
                            )}
                            <TakeActionSectionButtons>
                                <PrimaryButton
                                    type="button"
                                    onClick={() => {
                                        logAction("SWITCH_INV_BUTTON");
                                        window.open(content.switchUrl(memberData.accountId), '_blank', 'noopener');
                                    }}
                                    data-id={takeActionTestIds.switchInvBtn}
                                >
                                    Switch investments{opensInNewWindow}
                                </PrimaryButton>
                                <TalkToCoachLink id="coach-link-3" />
                            </TakeActionSectionButtons>
                        </>
                    )}
                </>
            )
        }
    }

    const getContributionsContent = () => {
        if (!hasContributions) { return null }

        const contInfo = (
            <>
                <Paragraph data-id={takeActionTestIds.concessionalContributions}>
                    <strong>Make before tax (concessional) contributions of {asDecimalCurrency(proposedContribution.salarySacrifice / frequencyValues.regularContributions.value)} {frequencyValues.regularContributions.label}</strong> into your {memberData.productName} account.
                </Paragraph>
                <Paragraph>This is based on what you told us you can afford and has been converted to a pre-tax amount.</Paragraph>
                <Paragraph marginBottom="0px">You can set this up as either a:</Paragraph>
                <TakeActionListCard>
                    <ul>
                        <li>
                            <Card>
                                <Text fontWeight="semibold" display="block">Salary Sacrifice arrangement with your employer</Text>
                                <Text>Pay some of your pre-tax salary into super. </Text>
                                <LearnMoreLink
                                    href={content.salarySacrificeLearnMore}
                                    onClick={() => logAction("SALARY_SACRIFICE_LEARN_MORE")}
                                    data-id={takeActionTestIds.salarySacrificeLearnMore}
                                >
                                    Learn more
                                </LearnMoreLink>
                            </Card>
                        </li>
                        <li>
                            <Card>
                                <Text fontWeight="semibold" display="block">Personal deductible contribution</Text>
                                <Text>Add to your super and get a tax deduction. </Text>
                                <LearnMoreLink
                                    href={content.personalDeductibleLearnMore}
                                    onClick={() => logAction("PERSONAL_DEDUCTIBLE_LEARN_MORE")}
                                    data-id={takeActionTestIds.personalDeductibleLearnMore}
                                >
                                    Learn more
                                </LearnMoreLink>
                            </Card>
                        </li>
                    </ul>
                </TakeActionListCard>
                <Paragraph data-id={takeActionTestIds.nonConcessionalContributions}>
                    <strong>Make after tax (non-concessional) contributions of {asDecimalCurrency(showNcc(proposedContribution) ? proposedContribution.personal / frequencyValues.regularContributions.value : 0.00)} {frequencyValues.regularContributions.label}</strong> into your {memberData.productName} account.
                </Paragraph>
            </>
        );

        const contBtn = isMlc ? (
            <Card>
                <PrimaryButton
                    type="button"
                    onClick={() => {
                        logAction("NON_CONCESSIONAL_CONT_LINK");
                        window.open(content.contributionsUrl(memberData.accountId), '_blank', 'noopener');
                    }}
                    data-id={takeActionTestIds.makeContBtn}
                >
                    Make a contribution{opensInNewWindow}
                </PrimaryButton>
            </Card>
        ) : (
            <Card>
                <PrimaryButton type="button" data-id={takeActionTestIds.bpayBtn}
                    onClick={() => {
                        setBpayModalContent(content.bpayInfo(bpayRef));
                        logAction("BPAY_CONT_BUTTON");
                        setIsInfoModalOpen(true);
                    }}
                >
                    Make a contribution - BPay
                </PrimaryButton>
            </Card>
        );

        return (
            <>
                <TakeActionImage>
                    <img src={content.contributionsImg} alt="" />
                </TakeActionImage>
                {contInfo}
                <TakeActionSectionButtons>
                    {contBtn}
                    <TalkToCoachLink id="coach-link-4" />
                </TakeActionSectionButtons>
            </>
        )
    };

    const getHelpContent = () => {
        return (
            <MobileDeviceQ
                content={
                    <>
                        <TakeActionImage>
                            <img src={content.coachImg} alt="" />
                        </TakeActionImage>
                        <Paragraph fontWeight="semibold">{commonHelpText.financialAdvice}</Paragraph>
                        <Paragraph>{commonHelpText.asAMember}</Paragraph>
                        <Paragraph>{commonHelpText.yourGoals}</Paragraph>
                        <Paragraph marginBottom="10px">{commonHelpText.hereToHelp2}</Paragraph>
                        <TakeActionSectionButtons marginBottom="10px"><FinancialAdviceLink /></TakeActionSectionButtons>
                    </>
                }
                mobileContent={
                    <>
                        <Card display="flex" alignItems="center">
                            <TakeActionImage>
                                <img src={content.coachImg} alt="" />
                            </TakeActionImage>
                            <Paragraph marginLeft="20px" fontWeight="semibold">{commonHelpText.financialAdvice}</Paragraph>
                        </Card>
                        <Paragraph>{commonHelpText.asAMember}</Paragraph>
                        <Paragraph>{commonHelpText.yourGoals}</Paragraph>
                        <Paragraph marginBottom="10px">{commonHelpText.hereToHelp2}</Paragraph>
                        <TakeActionSectionButtons marginBottom="10px"><FinancialAdviceLink /></TakeActionSectionButtons>
                    </>
                }
            />
        )
    }

    const hasContributions = contributionAmount > 0;
    const hasRiskProfile = riskProfileMixType !== null;

    return (
        <>
            <MobileDeviceQ
                content={
                    ((!hasContributions && !hasRiskProfile) ? (
                        <Card display="flex" marginTop="40px" data-id={takeActionTestIds.noRpOrConts}>
                            <Card marginRight="15px">
                                <Heading el="h3" fontSize="tiny" marginBottom="20px">{commonHelpText.hereToHelp}</Heading>
                                <Paragraph>{commonHelpText.gettingHelp}</Paragraph>
                                <Paragraph>{commonHelpText.getAdvice}</Paragraph>
                                <Paragraph>{commonHelpText.callUs}</Paragraph>
                                <BookingBtn />
                            </Card>
                            <Image src={content.coachImg} alt="" htmlHeight="300px" />
                        </Card>
                    ) : (
                        <TakeActionContainer>
                            {hasRiskProfile && (
                                <TakeActionSection data-id={takeActionTestIds.updateYourInvestments}>
                                    <TakeActionSectionHeading>
                                        <Heading el="h3">Update your investments</Heading>
                                    </TakeActionSectionHeading>
                                    <TakeActionContent>{getInvestmentsContent(false)}</TakeActionContent>
                                </TakeActionSection>
                            )}
                            {hasContributions && (
                                <TakeActionSection data-id={takeActionTestIds.updateYourContributions}>
                                    <TakeActionSectionHeading>
                                        <Heading el="h3">Update your contributions</Heading>
                                    </TakeActionSectionHeading>
                                    <TakeActionContent>{getContributionsContent()}</TakeActionContent>
                                </TakeActionSection>
                            )}
                            {(!hasRiskProfile || !hasContributions) && (
                                <TakeActionSection data-id={takeActionTestIds.getHelp}>
                                    <TakeActionSectionHeading>
                                        <Heading el="h3">Get help & advice</Heading>
                                    </TakeActionSectionHeading>
                                    <TakeActionContent>{getHelpContent()}</TakeActionContent>
                                </TakeActionSection>
                            )}
                        </TakeActionContainer>
                    ))
                }
                mobileContent={
                    ((!hasContributions && !hasRiskProfile) ? (
                        <>
                            <TakeActionHelpImageCard>
                                <Card>
                                    <Heading el="h3" fontSize="tiny">{commonHelpText.hereToHelp}</Heading>
                                </Card>
                                <Image src={content.coachImg} alt="" htmlHeight="130px" />
                            </TakeActionHelpImageCard>
                            <TakeActionHelpMainCard data-id={takeActionTestIds.noRpOrConts}>
                                <Grid>
                                    <Paragraph>{commonHelpText.gettingHelp}</Paragraph>
                                    <Paragraph>{commonHelpText.getAdvice}</Paragraph>
                                    <Paragraph>{commonHelpText.callUs}</Paragraph>
                                    <Card display="flex" justifyContent="center">
                                        <BookingBtn />
                                    </Card>
                                </Grid>
                            </TakeActionHelpMainCard>
                        </>
                    ) : (
                        <Card marginTop="20px">
                            {hasRiskProfile && (
                                <TakeActionAccordion
                                    title={
                                        <ActionsLabel el="h2" fontSize="tiny">Update your investments</ActionsLabel>
                                    }
                                    data-id={takeActionTestIds.updateYourInvestments}
                                    onChange={() => {
                                        logAction("NEXT_ACTIONS_INVESTMENTS");
                                        openAccordion(0);
                                    }}
                                    isOpen={accordions[0]}
                                >
                                    <TakeActionContent>{getInvestmentsContent(false)}</TakeActionContent>
                                </TakeActionAccordion>
                            )}
                            {hasContributions && (
                                <TakeActionAccordion
                                    title={
                                        <ActionsLabel el="h2" fontSize="tiny">Update your contributions</ActionsLabel>
                                    }
                                    data-id={takeActionTestIds.updateYourContributions}
                                    onChange={() => {
                                        logAction("NEXT_ACTIONS_CONTRIBUTIONS");
                                        openAccordion(1);
                                    }}
                                    isOpen={accordions[1]}
                                >
                                    <TakeActionContent>{getContributionsContent()}</TakeActionContent>
                                </TakeActionAccordion>
                            )}
                            {(!hasRiskProfile || !hasContributions) && (
                                <TakeActionAccordion
                                    title={
                                        <ActionsLabel el="h2" fontSize="tiny">Get help & advice</ActionsLabel>
                                    }
                                    data-id={takeActionTestIds.getHelp}
                                    onChange={() => {
                                        logAction("NEXT_ACTIONS_HELP");
                                        openAccordion(2);
                                    }}
                                    isOpen={accordions[2]}
                                >
                                    <TakeActionContent>{getHelpContent()}</TakeActionContent>
                                </TakeActionAccordion>
                            )}
                        </Card>
                    ))
                }
            />
            <StyledModal
                isOpen={isInfoModalOpen}
                onRequestClose={() => setIsInfoModalOpen(false)}
                title={bpayModalContent.title}
                align="left"
                shouldCloseOnOverlayClick={true}
                data-id={takeActionTestIds.nextStepsInfoModal}
            >
                {bpayModalContent.content}
            </StyledModal>
            <WiderModal
                isOpen={investmentsOpen}
                onRequestClose={() => setInvestmentsOpen(false)}
                title="Changes in investment fees"
                align="left"
                shouldCloseOnOverlayClick={true}
                data-id={takeActionTestIds.investmentTableModal}
            >
                {targetSets.map((set, i) => (
                    <TakeActionDetailsTable
                        targetSet={set}
                        memberData={memberData}
                        currentMix={currentMix}
                        tableIndex={i}
                        key={i}
                    />
                ))}
                <Card marginTop={4}>
                    <Paragraph>
                        *Investment fees and costs are always inclusive of any applicable performance fees. For the purposes of this table, the Investment fees and costs figure also includes the Transaction costs.
                    </Paragraph>
                    <Paragraph>
                        Other fees are not shown in the table above, such as:
                    </Paragraph>
                    <Card>
                        <ul>
                            <li>Buy/sell spread - please refer to the Statement of Advice provided for more details.</li>
                            <li>Administration fees and costs - <Text fontWeight="medium">your administration fee may change if you switch between investment options</Text>.</li>
                        </ul>
                    </Card>
                </Card>
            </WiderModal>
            <StyledModal
                isOpen={isCoachModalOpen}
                onRequestClose={() => {
                    setIsCoachModalOpen(false);
                    setTimeout(() => {
                        let toFocus = document.getElementById(coachBtnToFocus);
                        toFocus && toFocus.focus();
                    }, 100);
                }}
                align="left"
                shouldCloseOnOverlayClick={true}
                colorTheme="mlcWhite"
                hideFooterButtons={true}
                data-id={takeActionTestIds.talkToCoachModal}
            >
                <Paragraph fontWeight="semibold">{commonHelpText.hereToHelp}</Paragraph>
                <Paragraph>{commonHelpText.gettingHelp}</Paragraph>
                <Paragraph>{commonHelpText.getAdvice}</Paragraph>
                <Paragraph>{commonHelpText.callUs}</Paragraph>
                <TakeActionSectionButtons><BookingBtn /></TakeActionSectionButtons>
            </StyledModal>
        </>
    )
};

export default TakeActionDetails;