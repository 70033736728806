import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../history';
import CalculatorHeader from '../components/presentational/CalculatorHeader';
import ErrorPage from '../components/presentational/ErrorPage';
import NavBar from '../components/presentational/NavBar';
import { ErrorType } from '../enums';

// different non-error routes act as "checkpoints" if user refreshes - mimicked functionality from pre-AWS
const Routing = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route path="/" exact={true} render={(props) => (
					<>
						<CalculatorHeader />
						<NavBar {...props}/>
					</>
				)} />
				<Route path="/calculator" exact={true} render={(props: any) => (
					<>
						<CalculatorHeader />
						<NavBar {...props} activeStep={1}/>
					</>
				)} />
				<Route path="/advice" exact={true} render={(props: any) => (
					<>
						<CalculatorHeader />
						<NavBar {...props} activeStep={6}/>
					</>
				)} />
				<Route path="/nextsteps" exact={true} render={(props: any) => (
					<>
						<CalculatorHeader />
						<NavBar {...props} activeStep={7}/>
					</>
				)} />
				<Route path="/100" exact={true} render={() => <ErrorPage error={ErrorType.error100} />} />
				<Route path="/400" exact={true} render={() => <Redirect to="/500" />} />
				<Route path="/403" exact={true} render={() => <Redirect to="/401" />} />
				<Route path="/401" exact={true} render={() => <ErrorPage error={ErrorType.error401} />} />
				<Route path="/404" exact={true} render={() => <ErrorPage error={ErrorType.error404} />} />
				<Route path="/500" exact={true} render={() => <ErrorPage error={ErrorType.error500} />} />
				<Route path="/515" exact={true} render={() => <ErrorPage error={ErrorType.error515} />} /> {/* >100% growth assets */}
				<Route path="*" exact={true} render={() => <ErrorPage error={ErrorType.error404} />} />
			</Switch>
		</Router>
	);
};

export default Routing;
