import React from 'react';
import { logAction } from '../../../logic/functions/logging.function';
import { Paragraph } from '@mlc/web-ui-toolkit';
import footerTestIds from './Footer.elements';
import contactImg from '../../../assets/images/mkbs/booking-icon.png';
import { mlcBookingUrl, mlcContactNo } from '../Common/StaticContent';

const footer = {
    linksItems: [
        {
            title: 'Assumptions and limitations (PDF, 110KB)',
            pageurl: 'https://www.mlc.com.au/content/dam/mlc/documents/pdf/oats/oats-assumptions.pdf',
            onClick: () => {
                logAction("FOOTER_ASSUMPTIONS");
            },
            id: footerTestIds.footerAssumptions
        },
        {
            title: 'Terms and conditions (PDF, 89KB)',
            pageurl: 'https://www.mlc.com.au/content/dam/mlc/documents/pdf/oats/oats-terms-conditions.pdf',
            onClick: () => {
                logAction("FOOTER_TCS");
            },
            id: footerTestIds.footerTandC
        },
        {
            title: 'Financial Services Guide (PDF, 523KB)',
            pageurl: 'https://www.mlc.com.au/content/dam/mlc/documents/disclosure/calculators/personal-super-calculator-fsg.pdf',
            onClick: () => {
                logAction("FOOTER_FSG");
            },
            id: footerTestIds.footerFsg
        },
        {
            title: 'Privacy policy',
            pageurl: 'https://www.insigniafinancial.com.au/privacy-policy',
            includeNewTabMsg: true,
            onClick: () => {
                logAction("FOOTER_PRIVACY");
            },
            id: footerTestIds.footerPrivacy
        }
    ],

    legal: (
        <Paragraph>
            The Personal Super Calculator is provided by Actuate Alliance Services Pty Ltd ABN 40 083 233 925, AFSL 240959.<br />
            An investment in your super fund is not a deposit or liability of, and is not guaranteed by, Insignia Financial.
        </Paragraph>
    ),
    bookingUrl: mlcBookingUrl,
    contactNo: mlcContactNo,
    contactImg
};

export default footer;