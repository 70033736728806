import palmtree from '../../../../../assets/images/mkbs/palmtree.png';
import yogalady from '../../../../../assets/images/mkbs/yogalady.png';
import deckchair from '../../../../../assets/images/mkbs/deckchair.png';
import apron_man from '../../../../../assets/images/mkbs/man-in-apron.png';
import umbrella from '../../../../../assets/images/mkbs/umbrella.png';
import cocktail from '../../../../../assets/images/mkbs/cocktail.png';
import popcorn from '../../../../../assets/images/mkbs/popcorn.png';
import noodlebox from '../../../../../assets/images/mkbs/noodlebox.png';
import keys from '../../../../../assets/images/mkbs/keys.png';
import car from '../../../../../assets/images/mkbs/car.png';
import { mlcContactNo } from '../../../Common/StaticContent';

const retirementGoals = {
    asfaRetirementStandardsLink: "https://www.superannuation.asn.au/resources/retirement-standard/",
    palmtree: palmtree,
    comfortable: yogalady,
    custom: deckchair,
    apronman: apron_man,
    umbrella: umbrella,
    car: car,
    cocktail: cocktail,
    popcorn: popcorn,
    noodlebox: noodlebox,
    keys: keys,
    contactNum: mlcContactNo,
}

export default retirementGoals;