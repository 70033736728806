import { Card, Grid, LoadingIndicator, Paragraph, PrimaryButton } from '@mlc/web-ui-toolkit';
import React, { useEffect, useState } from 'react';
import { FrequencyValues } from '../../../logic/domains/calculator.domain';
import { Member } from '../../../logic/domains/memberData.domain';
import { TargetSetItem } from '../../../logic/domains/targetSet.domain';
import { formatCustomerName } from '../../../logic/functions/formatter';
import { logAction } from '../../../logic/functions/logging.function';
import { loadMemberData } from '../../../logic/functions/memberData.function';
import { getTargetSets } from '../../../logic/functions/targetSet.function';
import { focusHeading, redirectToError } from '../../../logic/functions/util';
import { KateCard, NavButtonGroup } from '../Common/Common.style';
import DetailsText from '../Common/DetailsText';
import { BackButton } from '../Common/NavButtons';
import TakeActionDetails from './TakeActionDetails';
import takeActionTestIds from './TakeAction.elements';

type Props = {
    handlePreviousStep: () => void;
    memberData?: Member;
    frequencyValues: FrequencyValues;
}



//calculator step 8 of 8
const TakeAction = ({ memberData, handlePreviousStep, frequencyValues }: Props) => {
    const [loading, setLoading] = useState(true);
    const [targetSets, setTargetSets] = useState<TargetSetItem[]>([]);
    const [mData, setMData] = useState(memberData);

    useEffect(() => {
        const getData = async () => {
            const loadedMemberData = await loadMemberData(memberData?.number);
            setMData({ ...memberData, ...loadedMemberData });

            // no selected risk profile = no target set
            if (loadedMemberData.riskProfileMixType) {
                const targetSetData = await getTargetSets();
                setTargetSets(targetSetData ? [...targetSetData] : []);
                if (targetSetData === null) {
                    redirectToError(500);
                }
            }
            setLoading(false);
            focusHeading();
        }

        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasContributions = mData!.contributionAmount > 0;
    const hasRiskProfile = mData!.riskProfileMixType !== null;

    return (
        <>
            <LoadingIndicator isOpen={loading} message="Just a moment..." variant="fullscreen" data-id={takeActionTestIds.nextStepsLoading} />
            {!loading && (
                <>
                    <KateCard>
                        <Grid>
                            <Card marginTop="25px" marginBottom="25px">
                                <DetailsText fontWeight="semibold" el="h2" fontSize="tiny">{formatCustomerName(memberData!.firstName)}, thanks for using the Personal Super Calculator.</DetailsText>
                                {(hasContributions || hasRiskProfile) && <Paragraph marginTop="15px">Taking the time to review your super is important, let's put your results into action today. Please read your personalised super report which outlines our recommendations and costs before implementing any changes.</Paragraph>}
                            </Card>
                        </Grid>
                    </KateCard>

                    <Card marginBottom="25px" data-id={takeActionTestIds.nextStepsContainer}>
                        <Grid>
                            <Card marginBottom="40px">
                                <TakeActionDetails memberData={mData!} targetSets={targetSets} frequencyValues={frequencyValues} />
                            </Card>
                        </Grid>

                        <Grid>
                            <NavButtonGroup>
                                <PrimaryButton
                                    onClick={() => {
                                        logAction("NEXT_ACTIONS_EXIT", true);
                                        window.close();
                                    }}
                                    type="button"
                                    data-id={takeActionTestIds.exitBtn}
                                >
                                    Exit
                                </PrimaryButton>
                                <BackButton
                                    onClick={() => {
                                        logAction("BACK_8");
                                        handlePreviousStep();
                                    }}
                                    data-id={takeActionTestIds.nextStepsPreviousTab}
                                />
                            </NavButtonGroup>
                        </Grid>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default TakeAction;