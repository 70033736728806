import projectionImg from '../../../assets/images/mkbs/icon-graph.png';
import contributionsImg from '../../../assets/images/mkbs/icon-piggybank.png';
import umbrellaImg from '../../../assets/images/mkbs/umbrella.png';
import onTrackImg from '../../../assets/images/mkbs/graph.png';
import reportImg from '../../../assets/images/mkbs/laptop-form.png';
import KateImg from '../../../assets/images/mkbs/kate.png';
import { mlcContactNo } from '../Common/StaticContent';

const welcome = {
    contactNo: mlcContactNo,
    assumptionsLink: "https://www.mlc.com.au/content/dam/mlc/documents/pdf/oats/oats-assumptions.pdf",
    assumptionsText: "assumptions and limitations (PDF, 136KB)",
    termsAndConditionsLink: "https://www.mlc.com.au/content/dam/mlc/documents/pdf/oats/oats-terms-conditions.pdf",
    termsAndConditionsText: "Terms and conditions (PDF, 93KB)",
    fsgLink:"https://www.mlc.com.au/content/dam/mlc/documents/disclosure/calculators/personal-super-calculator-fsg.pdf",
    fsgText:"Financial Services Guide (PDF, 198KB)",
    kateImg: KateImg,

    retirementGoalImg: umbrellaImg,
    riskProfileImg: projectionImg,
    contributionsImg: contributionsImg,
    onTrackImg: onTrackImg,
    getReportImg: reportImg,

}

export default welcome;