import palmtree from '../../../../../assets/images/plum/palmtree.png';
import yogalady from '../../../../../assets/images/plum/yogalady.png';
import deckchair from '../../../../../assets/images/plum/deckchair.png';
import apron_man from '../../../../../assets/images/plum/man-in-apron.png';
import umbrella from '../../../../../assets/images/plum/icon-retirement.png';
import cocktail from '../../../../../assets/images/plum/cocktail.png';
import popcorn from '../../../../../assets/images/plum/popcorn.png';
import noodlebox from '../../../../../assets/images/plum/noodlebox.png';
import keys from '../../../../../assets/images/plum/keys.png';
import car from '../../../../../assets/images/plum/car.png';
import { plumContactNo } from '../../../Common/StaticContent';

const retirementGoals = {
    asfaRetirementStandardsLink: "https://www.superannuation.asn.au/resources/retirement-standard/",
    palmtree: palmtree,
    comfortable: yogalady,
    custom: deckchair,
    apronman: apron_man,
    umbrella: umbrella,
    car: car,
    cocktail: cocktail,
    popcorn: popcorn,
    noodlebox: noodlebox,
    keys: keys,
    contactNum: plumContactNo,
}

export default retirementGoals;
